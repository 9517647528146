import { Reaction } from '@/components/chat/ChatBubble';
import PocketBase, { RecordSubscription } from 'pocketbase';

export const pb = new PocketBase(process.env.NEXT_PUBLIC_POCKETBASE);
pb.autoCancellation(false);

export const subscriptions = new Map<string, (e: any) => void>();

export function subscribe<T>(collectionName: string, callback: (e: RecordSubscription<T>) => void, options?: any) {
    if (subscriptions.has(collectionName)) {
        console.warn(`Already subscribed to ${collectionName}`);
        return;
    }
    subscriptions.set(collectionName, callback);
    pb.realtime.subscribe(collectionName, callback, options);
    console.info(`Subscribed to ${collectionName}`);
}

export const unsubscribe = (collectionName: string) => {
    if (!subscriptions.has(collectionName)) {
        console.warn(`No subscription found for ${collectionName}`);
        return;
    }
    try {
        pb.realtime.unsubscribe(collectionName).catch(error => console.log('Error unsubscribing from', collectionName, error));
    } catch (error) {
        console.log('Error unsubscribing from', collectionName, error);
    }
    subscriptions.delete(collectionName);
    console.info(`Unsubed from ${collectionName}`);
};

export type PbUser = {
    id: string;
    username: string;
    created: string;
    updated: string;
    isOnline: boolean;
    lastSeen: string;
};

export type PbChat = {
    id: string;
    freeChat: boolean;
    members: string[];
    lastMessage: string;
    name: string;
    created: string;
    updated: string;
    seenBy: string[];
    lastMessageAt: string;
    expand: {
        members: PbUser[];
    };
};
export type PbChatInsert = {
    freeChat?: boolean;
    members: string[];
    lastMessage: string;
    name: string;
};

export type PbMessage = {
    id: string;
    created: string;
    updated: string;
    sender: string;
    conversation: string;
    message: string;
    seenBy: string[];
    asset: string | null;
    blurAsset: string | null;
    reaction: Reaction;
    assetType: 'image' | 'video';
    unlockPrice: number;
    collectionId: string;
    videoPoster: string | null;
    isAssetUnlocked: boolean;
    systemMessage: boolean;
};
export type PbMessageInsert = {
    sender: string;
    conversation: string;
    message: string;
    seenBy: string[];
    asset: File | null;
    blurAsset: File | null;
    assetType: 'image' | 'video' | 'text';
    videoPoster: File | null;
    unlockPrice: number;
    systemMessage?: boolean;
};

export type PbOnlineUsers = {
    id: string;
    collectionId: string;
    collectionName: string;
    created: string;
    updated: string;
    userId: string;
};

export type PbGlobalChat = {
    id: string;
    collectionId: string;
    collectionName: string;
    created: string;
    updated: string;
    chatImage: string;
    members: string[];
    admin: string;
    seenBy: string[];
    lastMessage: string;
    chatName: string;
    displayName: string;
    readBy: string[];
};

export type PbGlobalChatMessage = {
    id: string;
    collectionId: string;
    collectionName: string;
    created: string;
    updated: string;
    pinned: boolean;
    message: string;
    asset: string;
    blurAsset: string;
    videoPoster: string;
    assetType: 'text' | 'video' | 'image';
    unlockPrice: number;
    unlockedBy: string[];
    gChat: string;
    reaction: string;
};
export type PbGlobalChatMessageInsert = {
    message: string;
    asset: File | null;
    blurAsset: File | null;
    price: number;
    gChat: string;
};

export type PbNotification = {
    id: string;
    collectionId: string;
    collectionName: string;
    created: string;
    updated: string;
    title: string;
    text: string;
    imageURL: string;
    videoURL: string;
    type: string;
    contentId: string;
    fromUser: string;
    toUser: string;
    read: boolean;
};

export type PbNotificationInsert = {
    title: string;
    text: string;
    imageURL: string;
    videoURL?: string;
    type: 'colab' | 'tip' | 'sub' | 'info';
    contentId: string;
    fromUser: string;
    toUser: string;
    read?: boolean;
};
