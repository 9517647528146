import { CreatorCategory, Role, User } from '@/db/schema';
import { create } from 'zustand';

export type UserStore = User & {
    isAdmin: boolean;
    setUserInfo: (data: Partial<User>) => void;
};

export const useUser = create<UserStore>(set => ({
    id: '',
    username: '',
    coins: 0,
    role: [],
    verifedEmail: false,
    email: '',
    isAdmin: false,
    providerId: null,
    inviteCode: '',
    promoCode: '',
    hashPassword: '',
    firstName: '',
    lastName: '',
    avatar: '',
    cover: '',
    about: '',
    fullName: '',
    createdAt: new Date(),
    image: '',
    verifiedCreator: false,
    price: 0,
    messagePrice: 0,
    recievePicPrice: 0,
    bio: '',
    phoneNumber: '',
    city: '',
    porfolio: '',
    age: 18,
    bday: '',
    pushNotificationsEnabled: false,
    pushNotificationsData: {},
    adminNote: '',
    creatorCategory: null as CreatorCategory | null,
    connectedAccounts: [],
    activeAccount: true,
    setUserInfo: ({ id, username, coins, role, verifedEmail, email, ...rest }) =>
        set(state => ({
            ...state,
            id,
            username,
            coins,
            role,
            isAdmin: role?.includes(Role.Admin) ?? false,
            verifedEmail,
            email,
            ...rest
        }))
}));
