'use client';

import * as React from 'react';
import { motion, MotionProps } from 'framer-motion';
import { SVGProps } from 'react';
import { useTheme } from 'next-themes';
type AKLogoProps = SVGProps<SVGSVGElement> & MotionProps & {
  size?: number;
  fill?: string;
  stroke?: string;
  strokeWidth?: string;
  className?: string;
  style?: React.CSSProperties;
};
const AkLogo: React.FC<AKLogoProps> = props => {
  const {
    resolvedTheme
  } = useTheme();
  return <motion.svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 1.5,
    ...props.style
  }} className={props.className} viewBox="0 0 759 1402" width={props.size} height={props.size} {...props} data-sentry-element="unknown" data-sentry-component="AkLogo" data-sentry-source-file="AkLogo.tsx">
            <path d="M1810.57 529.681c-87.05 29.091-173.45 68.954-252.6 229.866-119.33 19.076-398.51 353.553-145.95 662.093 48.33 59.04 142.11 145.33 240.84 203.12 150.51 88.09 215.08 287.92 215.08 287.92 63.28-163.3 19.31-328.27-145.32-495.11 32.89 5.46 73.24 21.08 95.68 39.89 31.23 26.19 58.18 29.36 77.89 27.61 26.48.46 35.59-24.27 48.55-58.08l9.09-6.58c12.98 5.73 25.97-.89 31.07-7.7 6.97-9.31 3.53-26.29-8.78-38.21 18.75 1.62 31.35-6.05 38.17-22.43 6.55-15.77-21.66-29.11-15.86-40.95 5.81-11.84 47.96-10.62 50.68-30.07 3.62-25.96-53.19-84.78-28.94-125.69 29.27-49.35 46.24-103.8 1.01-215.219-38.31-94.398-143.35-142.211-143.35-142.211 28.93-56.614 48.49-123.243 54.89-191.892-27.18-5.02-66.09 18.822-112.44 60.95 9.73-50.663 6.38-96.39-9.71-137.307Z" style={{
      fill: props.fill ?? 'hsl(var(--foreground))',
      stroke: props.stroke,
      strokeWidth: props.strokeWidth ?? '18.75px'
    }} transform="translate(-1300.198 -520.306)" data-sentry-element="path" data-sentry-source-file="AkLogo.tsx" />
        </motion.svg>;
};
export default AkLogo;