import { CreatorCategory } from '@/db/schema';
import { z } from 'zod';

export const SignInSchema = z.object({
    login: z.string().min(2, 'Přihlašovací jméno musí mít minimálně 2 znaky'),
    password: z.string().min(8)
});

export const BecomeAkSchema = z
    .object({
        firstName: z.string().min(2, 'Jméno musí mít minimálně 2 znaky').max(50, 'Jméno musí mít maximálně 50 znaků'),
        lastName: z.string().min(3, 'Příjmení musí mít minimálně 3 znaky').max(50, 'Příjmení musí mít maximálně 50 znaků'),
        username: z
            .string()
            .regex(/^[a-zA-Z0-9._-]*$/i, 'uživatelské jméno nesmí obsahovat diakritiku, mezery ani speciální znaky')
            .min(3, 'Uživatelské jméno musí mít minimálně 3 znaky')
            .max(50, 'Uživatelské jméno musí mít maximálně 50 znaků'),
        email: z.string().email('Neplatný email'),
        password: z.string().min(8, 'Heslo musí mít minimálně 8 znaků'),
        passwordConfirm: z.string().min(8, 'Heslo musí mít minimálně 8 znaků'),
        phoneNumber: z.string().min(9, 'Telefoní číslo musí mít minimálně 9 znaků').max(15, 'Telefoní číslo musí mít maximálně 15 znaků'),
        birthday: z
            .string({ message: 'Toto pole je povinné' })
            .trim()
            .length(10, 'Datum musí být ve formátu DD.MM.YYYY')
            .regex(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/, 'Datum musí být ve formátu DD.MM.YYYY'),
        city: z.string().min(2, 'Město musí mít minimálně 2 znaky').max(50, 'Město musí mít maximálně 50 znaků'),
        portfolio: z.string(),
        creatorCategory: z.nativeEnum(CreatorCategory),
        promoCode: z.string().optional()
    })
    .refine(data => data.password === data.passwordConfirm, {
        message: 'Hesla se neshodují',
        path: ['passwordConfirm']
    });

export const ChangePasswordSchema = z.object({
    oldPassword: z.string().min(8, 'Heslo musí mít minimálně 8 znaků'),
    password: z.string().min(8, 'Heslo musí mít minimálně 8 znaků'),
    passwordConfirm: z.string().min(8, 'Heslo musí mít minimálně 8 znaků')
});

export const SignUpSchema = z
    .object({
        firstName: z.string().min(3, 'Jméno musí mít minimálně 3 znaky').max(50, 'Jméno musí mít maximálně 50 znaků'),
        lastName: z.string().min(3, 'Příjmení musí mít minimálně 3 znaků').max(50, 'Příjmení musí mít maximálně 50 znaků'),
        email: z.string().email('Neplatný email'),
        birthday: z.string({ message: 'Toto pole je povinné' }),
        username: z
            .string()
            .regex(/^[a-zA-Z0-9._-]*$/i, 'uživatelské jméno nesmí obsahovat diakritiku')
            .min(3, {
                message: 'Uživatelské jméno musí mít minimálně 3 znaky.'
            })
            .max(50, {
                message: 'Uživatelské jméno musí mít maximálně 50 znaků.'
            }),
        password: z.string().min(8, { message: 'Heslo musí mít minimálně 8 znaků' }),
        passwordConfirm: z.string().min(8, { message: 'Heslo musí mít minimálně 8 znaků' })
    })
    .refine(data => data.password === data.passwordConfirm, {
        message: 'Hesla se neshodují',
        path: ['passwordConfirm']
    });

export const GirlFormSchema = z.object({
    id: z.string({ message: 'Vyber si uživatele! Toto pole je povinné' }),
    price: z.number().int().min(100, 'Cena musí být minimálně 100 Kč'),
    messagePrice: z.number().int().min(5, 'Cena za zprávu musí být minimálně 5 Kč'),
    image: z.string().optional(),
    bio: z.string().min(5, 'Zkus se trochu více rozepsat prosím').max(500).optional()
});

export const GirlEditFormSchema = z.object({
    id: z.string({ message: 'Vyber si uživatele! Toto pole je povinné' }),
    price: z.number().int().min(100, 'Cena musí být minimálně 100 Kč').optional(),
    firstName: z.string().min(2, 'Jméno musí mít minimálně 3 znaky').max(50, 'Jméno musí mít maximálně 50 znaků').optional(),
    messagePrice: z.number().int().min(5, 'Cena za zprávu musí být minimálně 5 Kč').optional(),
    recievePicPrice: z.number().int().min(65, 'Máme nastaveno minimální cenu za přijedí obrázkové zprávy na 65 Kč').optional(),
    image: z.string().optional().optional(),
    verified: z.boolean().optional(),
    bio: z.string().max(500).optional(),
    creatorCategory: z.nativeEnum(CreatorCategory).optional()
});

export const GirlContentFormSchema = z.object({
    text: z.string({ message: 'Toto pole je povinné' }).min(1, 'Toto pole je povinné.').max(500, 'Nesmí přesahovat délku 500 znaků.'),
    colabWith: z.string().optional().nullable(),
    exclusive: z.boolean().default(false),
    exclusivePrice: z.number().int().optional()
});

export const MessageAssetFormSchema = z.object({
    exclusive: z.boolean().default(true),
    exclusivePrice: z.number().int().optional()
});
