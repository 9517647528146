import { boolean, integer, pgTable, primaryKey, serial, text, timestamp } from 'drizzle-orm/pg-core';
import { users } from './schema';
import { relations } from 'drizzle-orm';

export const AppSettingsTable = pgTable('appSettings', {
    id: serial('id').primaryKey(),
    blurValue: integer('blurValue').default(75).notNull(),
    maintanance: boolean('maintanance').default(false).notNull()
});

export const CoinsTopupTable = pgTable('coinsTopup', {
    id: serial('id').primaryKey(),
    amount: integer('amount').notNull(),
    createdAt: timestamp('createdAt').defaultNow(),
    userId: text('userId')
        .notNull()
        .references(() => users.id)
});

export const coinsTopupRelations = relations(CoinsTopupTable, ({ one }) => ({
    user: one(users, {
        fields: [CoinsTopupTable.userId],
        references: [users.id]
    })
}));

export type CoinsTopup = typeof CoinsTopupTable.$inferSelect;
export type AppSettings = typeof AppSettingsTable.$inferSelect;
